import React from "react";

const ferticalrecomander: React.FC = () => {
  return (
    <div>
      <iframe
        src="http://localhost:8501"
        title="Streamlit App"
        width="100%"
        height="600px"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default ferticalrecomander;
